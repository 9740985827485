<template>
  <div class="serch">
    <!-- https://element.eleme.cn/#/zh-CN/component/form -->
    <h3>创建组织
    </h3>
    <p>意见箱主体：</p>

    <div style="margin: 20px;"></div>
    <el-form :label-position="labelPosition" label-width="80px">
      <el-form-item label="组织名称" :hide-required-asterisk="true" :rules="[
        { required: true, message: '请输入邮箱地址', trigger: 'blur' }
      ]">
        <el-input v-model="formLabelAlign.name"></el-input>
      </el-form-item>
      <el-form-item label="组织简介">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
        </el-input>
      </el-form-item>
      <div class="ser-but"> <el-button type="primary">主要按钮</el-button></div>

    </el-form>
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?181b2d56cf41a8d4e9262a313c2bacbc";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>
  <script>
  import cloudbase from "@cloudbase/js-sdk";
  // import Searchcom from '../../components/Searchcom.vue'
  export default {
    name: "Search",
    components: {
      // Searchcom,
    },
    data() {
      return {
        labelPosition: 'top',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      }
      };
    },
    
    created() {

    },
    methods: {
 
    },
  };
  </script>
  
<style scoped>
.serch {
  width: 1200px; 
  margin: auto;
}

.ser-but {
  width: 100px;
  margin: auto;
}
</style>